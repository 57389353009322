<template>
  <footer class="page-footer">
    <div class="container">
      <ApolloFooter
        v-if="content"
        class="generic-content__footer"
        :content="content.properties.footer">
        <template #suffix>
          <div class="footer-suffix">
            <a
              href="#"
              @click="onClickPrivacySettings">
              Privacy Settings
            </a>

            <jump-to-top />
          </div>
        </template>
      </ApolloFooter>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>{{ siteName }}</h3>
          <div class="page-footer__line">
            <span class="page-footer__line-section">
              1st Floor, Vantage London, Great West Road, Brentford, TW8 9AG
            </span>
            <span class="page-footer__line-section">
              Telephone: 0330 123 5375
            </span>
            <span class="page-footer__line-section">
              Email: info@gl-assessment.co.uk
            </span>
          </div>

          <div class="page-footer__line">
            <span>
              Copyright &copy; 2017-{{ new Date().getFullYear() }}
              {{ siteName }}, All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type { ContentItem } from "@edco/apollo";
import type { Home } from "~/models/generated/Home";

const props = defineProps<{
  international : boolean,
  content : ContentItem<Home> | null
}>();

const siteName = computed( () => {
  if ( !props.international ) {
    return "GL Assessment";
  }

  return "GL Education";
} );

function onClickPrivacySettings ( e : Event ) {
  e.preventDefault();
  window.semaphore.push( ["showPreferences"] );
}
</script>

<style scoped lang="scss">
.page-footer {
  margin-top: 60px;
  background: #333;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  font-size: 14px;

  h3 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    color: var( --color-primary-500 );
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  &__column {
    margin-bottom: 20px;
  }
}

.page-footer-links {
  padding: 0;
  margin: 0;
  list-style: none;
}

::v-deep( .footer-content__row-title ), ::v-deep( .footer-content__column-content h3 ) {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: var( --color-primary-500 );
}

::v-deep( .footer-content__column ) {
  margin-bottom: 20px;
}

::v-deep( .footer-content__column-content p ) {
  margin-bottom: 5px;
}

::v-deep( .footer-content__column-content a ) {
  color: #fff;
  text-decoration: none;
}

::v-deep( .footer-content__column-content a:hover ) {
  text-decoration: underline;
}

.footer-suffix {
  display: flex;
  justify-content: space-between;
}
</style>
