<template>
  <header
    class="page-header">
    <div class="container">
      <div
        :class="['main-navigation', {
          'main-navigation--open': showOverlay,
        }]">
        <a
          class="sr-only"
          href="#main">
          Skip to main content
        </a>

        <nuxt-link
          href="/"
          class="nav-icon"
          :class="cssClass">
          <img
            :src="imageUrl"
            :srcset="`${imageUrl}?height=60 1x, ${imageUrl}?height=120 2x`"
            alt="GL Logo"
            class="main-navigation__logo">
          <img
            :src="printImageUrl"
            :srcset="`${printImageUrl}?height=60 1x, ${printImageUrl}?height=120 2x`"
            alt="GL print friendly logo"
            class="main-navigation__logo--print">
          <span
            class="main-navigation__logo-text"
            :class="{ international }">
            Support
          </span>
        </nuxt-link>

        <button
          v-if="content?.properties.headerLinks?.length"
          class="main-navigation__toggle"
          aria-label="Open main navigation"
          @click="showOverlay = !showOverlay">
          <a-icon icon="fa:regular:bars" />
        </button>

        <div
          ref="clickOutside"
          class="main-navigation__collapse">
          <div class="main-navigation__top-links">
            <nuxt-link
              v-if="content?.properties.mainSiteLink"
              :to="content.properties.mainSiteLink"
              class="main-navigation__top-link">
              <a-icon icon="fa:regular:chevron-left" />
              Back to main website
            </nuxt-link>
            <site-switcher
              v-slot="{ current }"
              class="site-switcher">
              <span class="site-switcher__content">
                <a-icon
                  icon="fa:solid:globe"
                  aria-hidden="true" />
                <span>
                  {{ current?.label ?? 'Unknown' }}
                </span>
                <a-icon icon="fa:solid:arrow-down" />
              </span>
            </site-switcher>
          </div>

          <nav class="main-navigation__nav">
            <ul
              v-if="content?.properties.headerLinks"
              class="main-navigation__list">
              <li
                v-for="(headerLink, index) in content.properties.headerLinks"
                :key="index"
                class="main-navigation__item"
                @mouseover="openMenuItem( headerLink.properties.link?.label ?? '' )"
                @mouseleave="closeMenuItem( headerLink.properties.link?.label ?? '' )">
                <nuxt-link
                  class="main-navigation__link"
                  :class="{ 'active': menuItemsState[headerLink.properties.link?.label ?? ''] }"
                  :to="headerLink.properties.link?.url">
                  {{ headerLink.properties.link?.label ?? '' }}

                  <a-icon
                    v-if="headerLink.properties.dropdownLinks?.length"
                    icon="fa:solid:chevron-down"
                    :rotation="menuItemsState[headerLink.properties.link?.label ?? ''] ? 180 : undefined" />
                </nuxt-link>

                <template v-if="headerLink.properties.dropdownLinks?.length">
                  <mega-menu
                    v-cloak
                    v-if="menuItemsState[headerLink.properties.link?.label ?? '']">
                    <ul class="main-navigation__dropdown">
                      <li
                        v-for="(childLink, innerIndex) in headerLink.properties.dropdownLinks as Link[]"
                        :key="innerIndex"
                        class="main-navigation__item">
                        <nuxt-link
                          class="main-navigation__link"
                          :to="childLink?.url">
                          {{ childLink?.label ?? '' }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </mega-menu>

                  <ul class="main-navigation__dropdown main-navigation__dropdown--mobile">
                    <li
                      v-for="(childLink, innerIdx) in headerLink.properties.dropdownLinks as Link[]"
                      :key="innerIdx"
                      class="main-navigation__item">
                      <nuxt-link
                        class="main-navigation__link"
                        :to="childLink?.url">
                        {{ childLink?.label ?? '' }}
                      </nuxt-link>
                    </li>
                  </ul>
                </template>
              </li>

              <li
                v-if="search.data.value"
                class="main-navigation__item main-navigation__search"
                @mouseover="openMenuItem( 'search' )">
                <nuxt-link
                  class="main-navigation__link"
                  :class="{ 'active': menuItemsState['search'] }"
                  aria-label="Search"
                  :to="search.data.value.route.path">
                  <span class="d-lg-none">Search</span>
                  <a-icon icon="fa:regular:search" />
                </nuxt-link>

                <mega-menu
                  v-cloak
                  v-if="menuItemsState['search']"
                  full-width
                  @mouseleave="closeMenuItem( 'search' )">
                  <div class="main-navigation__dropdown">
                    <div class="container">
                      <quick-search @search="onSearch" />
                    </div>
                  </div>
                </mega-menu>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div class="browser-upgrade">
      <div class="container">
        <div class="browser-upgrade__content">
          <p class="browser-upgrade__text">
            You are using an <strong>outdated</strong> browser. Please
            <a
              href="https://browsehappy.com/"
              target="_blank"
              rel="noopener">
              upgrade your browser
            </a>
            to improve your experience and security.
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";
import type { ContentItem } from "@edco/apollo";
import type { Home } from "~/models/generated/Home";
import type { Link } from "~/models/generated/Link";

const props = defineProps<{
  content: ContentItem<Home> | null,
  international: boolean
}>();

const showOverlay = ref( false );
const menuItemsState : Record<string, boolean> = reactive( {} );
const preventHidingOverlay = ref( false );
const preventHidingMegaMenu = ref( false );

const clickOutside = ref<HTMLElement | null>();

const cssClass = computed( () => {
  let result = "nav-icon";

  if ( props.international ) {
    result += "--education";
  } else {
    result += "--assessment";
  }

  return result;
} );

const imageUrl = computed( () => {
  let result = "/images/gla-white.png";

  if ( props.international ) {
    result = "/images/gle-white.png";
  }

  if ( props.content?.properties.siteLogo ) {
    // @ts-ignore - generated model is wrong
    const logo = props.content.properties.siteLogo[0];

    result = logo.url;
  }

  return result;
} );

const printImageUrl = computed( () => {
  let result = "/images/gla-colour.jpeg";

  if ( props.international ) {
    result = "/images/gle-colour.jpeg";
  }

  if ( props.content?.properties.printSiteLogo ) {
    // @ts-ignore - generated model is wrong
    const logo = props.content.properties.printSiteLogo[0];

    result = logo.url;
  }

  return result;
} );

watch( showOverlay, ( val ) => {
  if ( val ) {
    preventHidingOverlay.value = true;
  }
} );

watch( preventHidingOverlay, () => {
  window.setTimeout( () => {
    preventHidingOverlay.value = false;
  }, 300 );
} );

watch( preventHidingMegaMenu, () => {
  window.setTimeout( () => {
    preventHidingMegaMenu.value = false;
  }, 300 );
} );

function openMenuItem ( alias : string ) {
  menuItemsState[alias] = true;
}

function closeMenuItem ( alias : string ) {
  menuItemsState[alias] = false;
}

function toggleMenuItem ( alias : string ) {
  menuItemsState[alias] = !menuItemsState[alias];
}

function getMenuItemState ( alias : string ) {
  return menuItemsState[alias];
}

function getMenuToggleClass ( alias : string ) {
  let result = {};

  if ( getMenuItemState( alias ) ) {
    result = {
      "main-navigation__accordion-toggle--open": true,
    };
  }

  return result;
}

function getIconRotation ( alias : string ) {
  let result = null;

  if ( getMenuItemState( alias ) ) {
    result = 180;
  }

  return result;
}

function closeOverlay () {
  if ( !preventHidingOverlay.value ) {
    showOverlay.value = false;
  }
}

async function onSearch ( value: string ) {
  if ( value && value.trim() !== "" ) {
    await navigateTo( `/search?query=${encodeURIComponent( value )}` );
  }
}

onClickOutside( clickOutside, closeOverlay );

const contentResource = useContentDeliveryResource();
const search = await contentResource.getByTypeSingle( "knowledgebaseSearch" );
</script>

<style lang="scss" scoped>
.page-header {
  background: #333;
  margin-bottom: 15px;
  min-height: 160px;
  padding: 30px 0;
  position: relative;
  color: #fff;

  @media ( width <= 991px ) {
    padding: 20px 0;
    min-height: auto;
  }

  .main-navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;

    .nav-icon {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      justify-content: center;
    }

    &__logo {
      height: 60px;

      &--print {
        display: none;
      }

      @media ( width <= 991px ) {
        max-height: 50px;
      }
    }

    &__logo-text {
      color: #999;
      margin-left: 25%;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.75rem;

      @media ( width <= 991px ) {
        font-size: 1.5rem;
      }

      &.international {
        margin-left: 29%;
      }
    }

    &__toggle {
      display: none;
      align-self: center;

      @media ( width <= 991px ) {
        display: block;
        font-size: 2rem;
        color: #fff;
        background: none;
        border: 0;
        flex-grow: 0;
        text-align: right;
        padding: 5px 15px;
      }
    }

    &__collapse {
      display: flex;
      flex-direction: column;

      @media ( width <= 991px ) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        top: 0;
        bottom: 0;
        right: -330px;
        max-width: 330px;
        width: 80%;
        z-index: 100;
        background: #fff;
        padding: 0;
        transition: right 0.4s;
        overflow-y: auto;
        -webkit-overflow-scrolling: auto;
      }
    }

    &__top-links {
      display: flex;
      justify-content: flex-end;
      gap: var( --s-4 );

      @media ( width <= 991px ) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        width: 100%;
      }
    }

    &__top-link {
      color: #fff;
      text-decoration: none;
      //padding: 10px 0;
      display: flex;
      align-items: center;
      font-size: 0.8rem;

      svg {
        display: inline-block;
        margin-right: 3px;
      }

      &:hover {
        color: var( --color-primary-500 );
      }

      @media ( width <= 991px ) {
        color: var( --color-primary-500 );
        padding: 10px 20px;
        font-weight: 600;
        font-size: 0.8rem;
        background: #f2f2f2;
        border-top: 1px solid #ccc;
      }
    }

    &__nav {
      flex-grow: 1;
      display: flex;
      align-items: center;

      @media ( width <= 991px ) {
        display: block;
        width: 100%;
        flex-grow: 1;
      }
    }

    &__list {
      display: flex;
      padding: 0;
      margin: 20px -10px 20px 0;
      list-style: none;

      @media ( width <= 991px ) {
        flex-direction: column;
        margin: 0;
      }
    }

    &__item {
      margin-right: 10px;
      cursor: pointer;

      &:hover .main-navigation__dropdown:not( .main-navigation__dropdown--mobile ) {
        display: block;
      }

      svg {
        display: inline-block;
        margin-left: 3px;
        font-size: 16px;

        @media ( width <= 991px ) {
          display: none;
        }

        &.fa-search {
          font-size: 20px;
        }
      }

      &:last-child {
        .main-navigation__link {
          margin-right: 0;
          padding-right: 0;
        }
      }

      @media ( width <= 991px ) {
        margin-right: 0;
      }
    }

    &__link {
      display: block;
      padding: 10px 40px 10px 0;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      min-width: 100px;

      &:hover,
      &.active {
        color: var( --color-primary-500 );
      }

      @media ( width <= 1199px ) {
        padding-right: 10px;
        min-width: auto;
      }

      @media ( width <= 991px ) {
        color: var( --color-primary-500 );
        font-weight: 600;
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;

        &:hover {
          background: #f2f2f2;
        }
      }
    }

    &__dropdown {
      background: #333;
      padding: 0 0 40px;
      list-style: none;
      margin: 0;

      @media ( width <= 991px ) {
        display: none !important;
      }

      &--mobile {
        display: none;
        background: none;
        padding: 0;

        @media ( width <= 991px ) {
          display: block !important;
        }
      }
    }

    &__dropdown .main-navigation__item {
      border-bottom: 1px solid #7f7f7f;

      &:first-child {
        border-top: 1px solid #7f7f7f;
      }
    }

    &__dropdown .main-navigation__link {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
    }

    &__dropdown--mobile .main-navigation__item {
      border: none !important;
    }

    &__dropdown--mobile .main-navigation__link {
      padding-left: 40px;
      font-size: 1rem;
      padding-top: 11px;
      padding-bottom: 11px;
    }

    &__search {
      .main-navigation__link {
        text-align: right;

        @media( width <= 991px ) {
          text-align: left;
        }
      }

      .quick-search {
        color: #fff;
      }

      input {
        width: 100%;
        padding: 10px 20px;
      }

      .search-icon {
        top: calc( 50% - 19px );
      }
    }
  }

  .browser-upgrade {
    display: none;
    background-color: var( --color-primary-500 );

    &__content {
      color: #fff;
      width: 100%;
    }

    &__text {
      background-color: var( --color-primary-500 );
      padding: 10px;
      margin-bottom: 0;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #ddd;
      }
    }
  }

  @media all and ( -ms-high-contrast: none ), ( -ms-high-contrast-active ) {
    .browser-upgrade {
      display: block;
    }
  }
}

@media ( width <= 991px ) {
  :root .main-navigation--open .main-navigation__collapse {
    right: 0;
  }
}

.site-switcher {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  ::v-deep( .dropdown__button ) {
    background: transparent;
    box-shadow: none;
    font-size: 0.8rem;
    color: #fff;

    @media ( width < 767px ) {
      color: var( --color-primary-500 );
    }

    &:hover {
      color: var( --color-primary-500 );
    }
  }

  ::v-deep( .dropdown__items ) {
    border-radius: var( --s-1 )
  }

  ::v-deep( .dropdown__menu-item ) {
    color: var( --text-color );

    &:hover {
      color: var( --color-primary-500 );
    }
  }
}

</style>
